export enum NGRXAuthType {
  saveToken = 'Save Token',
  saveUserProfile = 'Save User Profile',
  saveMSProfile = 'Save MS Profile',
  setReportsTo = 'Set Reports To',
  logOut = 'Log Out',
}

export enum NGRXAuthSource {
  AuthModule = 'Auth Module',
  AuthMode = 'Auth Mode',
}

export enum NGRXAuthModeType {
  setMode = 'Set Mode',

  init = 'Init'
}
