import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { IconType } from 'src/core/enums/icon.enum';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class IconComponent implements OnInit {
  @Input({ required: true }) name: keyof typeof IconType;
  @Input() width: string = '24';
  @Input() height: string = '24';
  @Input() color: string = '#000000';
  @Input() thickness: string = '1.5';

  public iconType: typeof IconType = IconType;

  constructor(private elRef: ElementRef) {}

  public ngOnInit(): void {
    if (this.height) {
      this.elRef.nativeElement.style.height = this.height;
    }
  }
}
