export enum Permission {
  feedbackDelete = 'feedback:delete',
  feedbackTemplateDelete = 'feedbackTemplate:delete',
  userDelete = 'user:delete',
  userAssignManager = 'user:assignManager',
  assistantCreateGoal = 'assistant:createGoal',
  scoreCardView = 'scoreCard:view',
  reviewCycleCreate = 'reviewCycle:create',
}

export enum AuthMode {
  Regular = 'regular',
  Manager = 'manager',
  Admin = 'admin',
}
