import { AuthenticationResult } from '@azure/msal-browser';
import { createAction, createActionGroup, props } from '@ngrx/store';
import { AuthMode } from 'src/core/enums/permissions.enum';
import { TeamMember } from 'src/core/interfaces/team/team.interface';
import { UserData } from 'src/core/interfaces/user/user.interface';
import {
  NGRXAuthModeType,
  NGRXAuthSource,
  NGRXAuthType
} from './auth-action.types';

export const AuthActions = createActionGroup({
  source: NGRXAuthSource.AuthModule,
  events: {
    [NGRXAuthType.saveToken]: props<{ token: string }>(),
    [NGRXAuthType.saveUserProfile]: props<{ userProfileData: UserData }>(),
    [NGRXAuthType.saveMSProfile]: props<{
      msProfileData: AuthenticationResult;
    }>(),
    [NGRXAuthType.setReportsTo]: props<{ reportsTo: TeamMember }>(),
  },
});

export const authActionLogOut = createAction(NGRXAuthType.logOut);

export const AuthModeActions = createActionGroup({
  source: NGRXAuthSource.AuthMode,
  events: {
    [NGRXAuthModeType.setMode]: props<{ mode: AuthMode }>(),
  },
});

export const initAuthModeStore = createAction(
  NGRXAuthModeType.init,
);